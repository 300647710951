import React from "react";

import SEO from 'components/seo';
import Introduction from 'components/introduction';

import 'style/index.scss';

const Index = () => {
  return (
    <div>
      <SEO />
      <Introduction />
    </div>
  );
};

export default Index;
