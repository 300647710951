import React from "react";

import { Helmet } from "react-helmet"
import { seo, title, url, profileIcon } from "content/introduction.json"

const SEO = () => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <link rel="icon" href={profileIcon} />
      <meta name="description" content={seo.description} />
      <meta name="image" content={profileIcon} />

      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={profileIcon} />

      <meta name="twitter:card" content={seo.description} />
      <meta name="twitter:creator" content={seo.twitterUsername} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={profileIcon} />
    </Helmet>
  );
};

export default SEO;
