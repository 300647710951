import React from "react";

import GithubIcon from "style/icons/github.svg";
import LinkedInIcon from "style/icons/linkedin.svg";
import MediumIcon from "style/icons/medium.svg";
import TwitterIcon from "style/icons/twitter.svg";
import {
  title,
  profilePicture,
  mainContent
} from "content/introduction.json"
import Header from './header';
import Footer from './footer';
// import 'style/pages/index.less';

const ProfilePhoto = () => {
  return (
    <div className="flex flex-row mb-10">
      <img className="h-32 rounded-full mx-auto border-green-500 border-4" src={profilePicture} alt="profile"/>
    </div>
  )
}

const IntroductionContent = () => {
  const {
    sayHi,
    describePart1: {
      part1,
      part2,
      place,
      experienceInYears,
      rails,
      part3,
      react,
      python,
      part4,
      part5,
      resume
    },
    describePart2,
    describePart3,
    describePart4,
    sayBye
  } = mainContent;

  return (
    <div className="flow flow-col space-y-4 font-semibold">
      <p>{sayHi.text}<span role="img" aria-label={sayHi.emoji.ariaLabel}>{sayHi.emoji.text}</span></p>
      <p>
        {part1}<span>{title}</span>{part2}<span>{place}</span>{experienceInYears}<span>{rails}</span>{part3}<span>{react}</span>{part4}<span>{python}</span>{part5}<a style={{ color: '#fa7f72' }} href={resume.link} target="_blank" rel="noreferrer">{resume.text}</a>.
      </p>
      <p>
        {describePart2}
      </p>
      <p>
        {describePart3}
      </p>
      <p>
        {describePart4}
      </p>
      <p>
        {sayBye.text}<span role="img" aria-label={sayBye.emoji.ariaLabel}>{sayBye.emoji.text}</span>
      </p>
    </div>
  );
}

const SocialLinks = () => {
  const {
    socialLinks: {
      github,
      linkedIn,
      twitter,
      medium
    }
  } = mainContent;

  return (
    <div className="flex flex-row justify-center mt-10">
      <a aria-label={github.ariaLabel} href={github.link} target="_blank" rel="noreferrer"><GithubIcon className="h-8 m-1 fill-current hover:text-yellow-600 transition-all duration-300 ease-in-out" /></a>
      <a aria-label={medium.ariaLabel} href={medium.link} target="_blank" rel="noreferrer"><MediumIcon className="h-8 m-1 fill-current hover:text-yellow-600 transition-all duration-300 ease-in-out" /></a>
      <a aria-label={twitter.ariaLabel} href={twitter.link} target="_blank" rel="noreferrer"><TwitterIcon className="h-8 m-1 fill-current hover:text-yellow-600 transition-all duration-300 ease-in-out" /></a>
      <a aria-label={linkedIn.ariaLabel} href={linkedIn.link} target="_blank" rel="noreferrer"><LinkedInIcon className="h-8 m-1 fill-current hover:text-yellow-600 transition-all duration-300 ease-in-out" /></a>
    </div>
  );
}

const IntroductionBody = () => {
  return (
    <div className="">
      <IntroductionContent />
      <SocialLinks />
    </div>
  )
}

export const PrevIntro = () => {
  return (
    <div className="content-wrpper">
      <ProfilePhoto />
      <IntroductionBody />
    </div>
  );
};

const Introduction = () => {
  return (
    <div className="container min-h-screen">
      <div className="flex flex-col w-full">
        <Header />
        <div className="flex flex-row">
          <div className="flex flex-col mx-auto w-3/5 p-10">
            <ProfilePhoto />
            <IntroductionBody />
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Introduction;
